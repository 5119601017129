import React, {useCallback, useEffect, useState} from "react";
import _ from "lodash";
import {Collapse, Form, message, Switch} from "antd";
import {ApiContactType, ApiProjectType, ApiTimelineItemType, NewRecordState} from "../../../constants/constants";
import {continueEdit} from "../../../redux/reducers/detailsPanel";
import {errorFromHttpResponse, isRequestCanceled} from "../../../helpers/error";
import {useDispatch, useSelector} from "react-redux";
import {loadTeam} from "../../../services/team";
import {loadContacts} from "../../../services/contacts";
import {FileTextFilled, InfoCircleOutlined, TeamOutlined, ToolOutlined} from "@ant-design/icons";
import {createEvent, getEvent, makeFavorite, refreshTimeline, updateEvent} from "../../../services/timeline";
import TeamMembersForEvent from "./TeamMembers";
import CreateContact from "../../Common/QuickCreate/CreateContact";
import FormProjectSelect from "./FormProjectSelect/FormProjectSelect";
import LocationSelect from "../../Common/Selects/LocationSelect";
import TimelineInputs from "./TimelineInputs";
import useDateTimeFormat from "../../../hooks/useDateTimeFormat";
import {formatContactName} from "../../../helpers/text";
import {LocationConflicts} from "../TimelineTable";
import {getDatesForApi} from "./DateAndTime";
import EventDeliverables from "./EventDeliverables";
import LabourLinesBlock from "./LabourLinesBlock/LabourLinesBlock";
import "./TimelineDetailsPanel.scss";
import AuditFormItem from "../../Common/AuditFormItem";
import {TagIcon} from "../../../helpers/Tags";
import {sanitizeHTML} from "../../../helpers/html";
import moment from "moment";

const mapContact = (contact) => ({
    value: contact.Id.toString(), label: formatContactName(contact), contact: contact,
});

const prepareFormData = (data) => {
    const formData = {
        ...data,
        TeamMemberIds: data.PeopleOrganizations?.map((tm) => tm.Id.toString()) || [],
        NewTeamMemberIds: data.PeopleOrganizations?.map((tm) => tm.Id.toString()) || [],
        DepartmentTags: data.DepartmentTags?.map((t) => t.AccountTagId.toString()),
        CategoryTags: data.CategoryTags?.map((t) => t.AccountTagId.toString()),
    };

    return formData;
};

const TimelineDetailsPanel = () => {

    const itemToEdit = useSelector((state) => state.detailsPanel.item);

    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [editItemState, setEditItemState] = useState(NewRecordState.Empty);
    const [isQuickCreateContactVisible, setIsQuickCreateContactVisible] = useState(false);
    const [isQuickCreateOrgVisible, setIsQuickCreateOrgVisible] = useState(false);
    const [isQuickCreatePlaceVisible, setIsQuickCreatePlaceVisible] = useState(false);
    const [isQuickCreateSpaceVisible, setIsQuickCreateSpaceVisible] = useState(false);

    const dateTimeFormats = useDateTimeFormat();

    const activePortfolio = useSelector((state) => state.projects.activePortfolio);
    const projects = useSelector((state) => state.projects.projects).filter((p) => p.State !== ApiProjectType.Archive);
    const allProjects = useSelector((state) => state.projects.portfolioProjects);

    const allContacts = useSelector((state) => state.contacts.contacts);
    const locations = allContacts.filter((c) => c.Type === ApiContactType.Space);
    const places = allContacts.filter((c) => c.Type === ApiContactType.Place).map(mapContact);

    const projectCategories = useSelector((state) => state.tags.projectCategories[itemToEdit?.ProjectId]) || [];

    const categories = useSelector((state) => state.tags.categories || []).filter(
        (t) => projectCategories.map((x) => x.AccountTagId).indexOf(t.AccountTagId) === -1
    );

    const projectDepartments = useSelector((state) => state.tags.projectDepartments[itemToEdit?.ProjectId]) || [];
    const departments = useSelector((state) => state.tags.departments || []).filter(
        (t) => projectDepartments.map((x) => x.AccountTagId).indexOf(t.AccountTagId) === -1
    );

    const statuses = useSelector((state) => state.tags.statuses || []);

    const selectedProjects = useSelector((state) => state.projects.activeProjects);
    const timelineProject = selectedProjects.length === 1 && !itemToEdit ? selectedProjects[0] : null;

    const temporaryDates = useSelector((state) => state.detailsPanel.temporaryDates);

    const isOptionalFieldDisabled = editItemState !== NewRecordState.ReadyForEdit;

    useEffect(() => {
        if (!activePortfolio) return;

        if (allContacts.length === 0) {
            loadContacts(activePortfolio.Id);
        }
    }, [activePortfolio]);

    useEffect(() => {
        if (!itemToEdit) return;

        setEditItemState(NewRecordState.ReadyForEdit);

        const formData = prepareFormData(itemToEdit);
        form.setFieldsValue(formData);
    }, [itemToEdit]);

    const prepareAndCreate = useCallback((finishedForm, dates) => {

        const {StartDate, EndDate, IsAllDay} = dates;

        const payload = {
            Name: finishedForm.Name,
            CurrentProjectId: finishedForm.ProjectId,
            StartDate,
            EndDate,
            IsAllDay,
        };

        createEvent(finishedForm.ProjectId, payload, ApiTimelineItemType.Task)
            .catch((err) => {
                const errorText = errorFromHttpResponse(err);
                message.error(errorText);
                throw new Error(errorText);
            })
            .then((response) => {
                dispatch(continueEdit({item: {...response.TaskDTO, ItemType: ApiTimelineItemType.Task}}));
                setEditItemState(NewRecordState.ReadyForEdit);
                refreshTimeline(ApiTimelineItemType.Task);
                message.success(`Event was created`);
            })
            .catch(console.error);

        setEditItemState(NewRecordState.RequiredFieldsSubmitted);
    }, [itemToEdit?.Id, dispatch]);

    const prepareAndUpdate = useCallback((finishedForm) => {
        const datesPayload = {
            StartDate: !_.isUndefined(finishedForm.startDate) ? finishedForm.startDate : itemToEdit.StartDate,
            EndDate: !_.isUndefined(finishedForm.endDate) ? finishedForm.endDate : itemToEdit.EndDate,
            StartTime: !_.isUndefined(finishedForm.startTime) ? finishedForm.startTime : itemToEdit.StartTime,
            EndTime: !_.isUndefined(finishedForm.endTime) ? finishedForm.endTime : itemToEdit.EndTime,
        };

        const newLocationContactIds = form.getFieldValue("NewLocationContactIds");
        const needReloadTeamMembers = finishedForm.TeamMemberContactIds?.length > 0 || newLocationContactIds?.length > 0;
        const newLocationContact = finishedForm.NewLocationContact || locations.find((l) => l.Id === finishedForm.Location?.ContactId);

        const NewTeamMemberIds = (form.getFieldValue("NewTeamMemberIds") || []).map((i) => parseInt(i, 10));

        const updatePayload = {
            Id: itemToEdit.Id,
            Bookings: itemToEdit.Bookings,
            Name: finishedForm.Name,
            Notes: sanitizeHTML(finishedForm.Notes),
            EnableLocationConflicts: finishedForm.EnableLocationConflicts,
            DepartmentTags: finishedForm.DepartmentTags.map((t) => parseInt(t, 10)),
            CategoryTags: finishedForm.CategoryTags.map((t) => parseInt(t, 10)),
            StatusTagId: finishedForm.StatusTag?.AccountTagId,
            CurrentProjectId: itemToEdit.ProjectId,
            NewTeamMemberContactIds: finishedForm.TeamMemberContactIds,
            TeamMemberIds: NewTeamMemberIds,
            NewLocationContact: newLocationContact,
            LocationIds: finishedForm.LocationIds,
            NewLocationContactIds: newLocationContactIds, ...datesPayload,
        };

        updateEvent(updatePayload)
            .then((response) => {
                if (needReloadTeamMembers) {
                    loadTeam(itemToEdit.ProjectId);
                    loadContacts(activePortfolio.Id);
                }
                dispatch(continueEdit({item: {...response.TaskDTO, ItemType: itemToEdit.ItemType}}));
                refreshTimeline(ApiTimelineItemType.Task);
            })
            .catch((err) => {
                if (!isRequestCanceled(err)) {
                    const errorText = errorFromHttpResponse(err);
                    message.error(errorText);
                    console.error(err);
                }
            });
    }, [itemToEdit?.Id, dispatch, locations]);

    const onFinish = useCallback((finishedForm) => {
        if (editItemState === NewRecordState.Empty) {
            prepareAndCreate(finishedForm, temporaryDates);
        }
        if (editItemState === NewRecordState.ReadyForEdit) {
            prepareAndUpdate(finishedForm);
        }
    }, [editItemState, prepareAndCreate, prepareAndUpdate]);

    const save = useCallback(async () => {
        try {
            const values = isOptionalFieldDisabled ? await form.getFieldsValue() : await form.validateFields();

            if (!isOptionalFieldDisabled || (values.Name && values.ProjectId)) {
                setEditItemState(NewRecordState.ReadyForEdit);
                onFinish(values);
            }
        } catch (err) {
            console.error(err);
        }
    }, [form, onFinish]);

    const statusOptions = statuses.map((status) => ({
        value: status.AccountTagId, title: status.Name, label: (<>
            <TagIcon icon={status.Icon}/> {status.Name} 
        </>),
    }));

    const changeHighlight = (checked) => {
        makeFavorite({
            IsFavorite: checked, ProjectId: itemToEdit.ProjectId, TaskId: itemToEdit.Id, TaskType: itemToEdit.ItemType,
        }).then(() => refreshTimeline(ApiTimelineItemType.Task));
    };

    const onDateChanged = (datesDto) => {
        const data = getDatesForApi(datesDto);
        onFinish({
            ...form.getFieldsValue(), ...data,
        });
    };

    const onTeamMembersChange = () => {
        onFinish({
            ...form.getFieldsValue(),
            TeamMemberContactIds: form.getFieldValue("TeamMemberContactIds"),
            NewTeamMemberIds: form.getFieldValue("NewTeamMemberIds"),
        });
    };

    const onBookingDelete = () => {
        onRefresh();
    };

    const onBookingUpdate = (wc) => {
        let item = {
            ...wc, ...itemToEdit,
            ItemType: ApiTimelineItemType.Task,
            Bookings: wc.Bookings,
            LabourLines: wc.LabourLines,
        };

        dispatch(continueEdit({item: item}));
    };

    const onRefresh = () => {
        return Promise.all([getEvent(itemToEdit.Id, itemToEdit.ProjectId, ApiTimelineItemType.Task)
            .then((item) => dispatch(continueEdit({item})))
            .catch(() => {
            }), refreshTimeline(),]);
    };

    return (
        <div className="side-panel with-collapse timeline-details-panel">
            <Collapse defaultActiveKey={["Information", "Team", "CrewManagement", "Reports", "LabourLines"]}>
                <Collapse.Panel
                    key="Information"
                    header={
                        <>
                            <InfoCircleOutlined/> {itemToEdit?.IsProjectTask ? "Project Information" : "Information"}
                        </>
                    }>
                    <Form
                        form={form}
                        labelCol={{span: 8}}
                        wrapperCol={{span: 16}}
                        layout="horizontal"
                        size="middle"
                        initialValues={{
                            ProjectId: timelineProject?.Id.toString(),
                            Project: timelineProject,
                        }}>
                        <FormProjectSelect projects={projects} project={itemToEdit?.Project}
                                           save={save}></FormProjectSelect>

                        <TimelineInputs
                            save={save}
                            form={form}
                            projectTimeZone={itemToEdit?.Project?.TimeZoneId}
                            isOptionalFieldDisabled={isOptionalFieldDisabled}
                            onDateChanged={onDateChanged}
                            startDate={itemToEdit?.StartDateTime}
                            endDate={itemToEdit?.EndDateTime}
                            isAllDay={itemToEdit?.IsAllDay}
                            isTimeOnly={itemToEdit?.IsTimeOnly}
                            statusOptions={statusOptions}
                            projectCategories={projectCategories}
                            categories={categories}
                            projectDepartments={projectDepartments}
                            departments={departments}
                            dateFormat={dateTimeFormats.date}
                            timeFormat={dateTimeFormats.time}
                            showShiftBookingInfo={itemToEdit?.Bookings?.length > 0}
                            isProjectTask={itemToEdit?.IsProjectTask}
                        />
                        <Form.Item label="Highlight" name="IsFavorite" valuePropName="checked"
                                   className="ant-form-item-without-validation">
                            <Switch disabled={isOptionalFieldDisabled} onChange={changeHighlight}/>
                        </Form.Item>
                        <AuditFormItem item={itemToEdit} label="Created" timeProp="CreatedAt" userProp="CreatedBy"/>
                        <AuditFormItem item={itemToEdit} label="Last Updated" timeProp="UpdatedAt"
                                       userProp="UpdatedBy"/>
                    </Form>
                </Collapse.Panel>
                <Collapse.Panel
                    key="Team"
                    header={
                        <>
                            <TeamOutlined/> Team
                        </>
                    }>
                    <Form form={form} labelCol={{span: 8}} wrapperCol={{span: 16}} layout="horizontal" size="middle">
                        <Form.Item
                            name={["LocationIds"]}
                            label={
                                <>{LocationConflicts({
                                    item: itemToEdit,
                                    className: "form-item-label-icon",
                                    projects: allProjects
                                })} Location</>
                            }
                            className="ant-form-item-without-validation">
                            <LocationSelect
                                onChange={({LocationIds, NewLocationContactIds}) => {
                                    form.setFieldsValue({
                                        LocationIds,
                                        NewLocationContactIds,
                                    });
                                }}
                                disabled={isOptionalFieldDisabled}
                                onChanged={save}
                                projectId={itemToEdit?.ProjectId}
                                setIsQuickCreatePlaceVisible={setIsQuickCreatePlaceVisible}
                                setIsQuickCreateSpaceVisible={setIsQuickCreateSpaceVisible}></LocationSelect>
                        </Form.Item>

                        <Form.Item
                            label="Event Affects Location Availability"
                            name="EnableLocationConflicts"
                            valuePropName="checked"
                            labelCol={{span: 18}}
                            wrapperCol={{span: 6}}>
                            <Switch disabled={isOptionalFieldDisabled} onChange={save}/>
                        </Form.Item>

                        <TeamMembersForEvent
                            form={form}
                            projectId={itemToEdit?.ProjectId}
                            isOptionalFieldDisabled={isOptionalFieldDisabled}
                            setIsQuickCreateOrgVisible={setIsQuickCreateOrgVisible}
                            setIsQuickCreateContactVisible={setIsQuickCreateContactVisible}
                            onChanged={onTeamMembersChange}></TeamMembersForEvent>
                    </Form>
                    <CreateContact
                        apiContactType={ApiContactType.Person}
                        isVisible={isQuickCreateContactVisible}
                        onClose={() => setIsQuickCreateContactVisible(false)}
                        onContactCreated={(contactId) => {
                            const values = form.getFieldsValue("NewTeamMemberIds");
                            onFinish({
                                ...values,
                                TeamMemberContactIds: [contactId],
                            });
                        }}
                    />
                    <CreateContact
                        apiContactType={ApiContactType.Organization}
                        isVisible={isQuickCreateOrgVisible}
                        onClose={() => setIsQuickCreateOrgVisible(false)}
                        onContactCreated={(contactId) => {
                            const values = form.getFieldsValue("TeamMemberIds");
                            onFinish({
                                ...values,
                                TeamMemberContactIds: [contactId],
                            });
                        }}
                    />
                </Collapse.Panel>
                {itemToEdit?.ItemType === ApiTimelineItemType.Task && (
                    <>
                        <Collapse.Panel
                            key="Reports"
                            header={
                                <>
                                    <FileTextFilled/> Reports
                                </>
                            }
                            className="crew-management-panel">
                            <EventDeliverables itemToEdit={itemToEdit}></EventDeliverables>
                        </Collapse.Panel>

                        <Collapse.Panel
                            key="LabourLines"
                            header={
                                <>
                                    <ToolOutlined/> Crew Management
                                </>
                            }
                            className="crew-management-panel">
                            <LabourLinesBlock
                                projectId={itemToEdit?.ProjectId}
                                taskId={itemToEdit.Id}
                                labourLines={itemToEdit?.LabourLines}
                                bookings={itemToEdit?.Bookings}
                                onBookingDelete={onBookingDelete}
                                onBookingUpdate={onBookingUpdate}
                                displayCreateBookings={itemToEdit?.LabourLines?.length > 0 && !itemToEdit?.IsLabourLineSynced}
                                onRefresh={onRefresh}></LabourLinesBlock>
                        </Collapse.Panel>
                    </>
                )}
            </Collapse>
            <CreateContact
                isEmailRequired={true}
                apiContactType={ApiContactType.Place}
                isVisible={isQuickCreatePlaceVisible}
                onClose={() => setIsQuickCreatePlaceVisible(false)}
                onContactCreated={(contactId, contact) => {
                    const values = form.getFieldsValue();
                    const spaceId = contact.ChildContacts[0].Id;

                    form.setFieldsValue({
                        NewLocationContactIds: [spaceId],
                    });
                    save();
                }}></CreateContact>
            <CreateContact
                places={places}
                isEmailRequired={true}
                apiContactType={ApiContactType.Space}
                isVisible={isQuickCreateSpaceVisible}
                onClose={() => setIsQuickCreateSpaceVisible(false)}
                onContactCreated={(contactId, contact) => {
                    form.setFieldsValue({
                        NewLocationContactIds: [contactId],
                    });
                    save();
                }}></CreateContact>
        </div>
    );
};

export default TimelineDetailsPanel;
