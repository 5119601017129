import React, {useRef, useState, useEffect, useCallback, useMemo} from 'react';
import '@syncfusion/ej2-base/styles/material.css';
import '@syncfusion/ej2-buttons/styles/material.css';
import '@syncfusion/ej2-inputs/styles/material.css';
import '@syncfusion/ej2-popups/styles/material.css';
import '@syncfusion/ej2-react-calendars/styles/material.css';
import '@syncfusion/ej2-react-schedule/styles/material.css';
import {
    ScheduleComponent, Month, Inject, DragAndDrop, Resize, ViewsDirective, ViewDirective
} from '@syncfusion/ej2-react-schedule';
import {useDispatch, useSelector} from 'react-redux';
import {message, Modal} from 'antd';
import {deleteBooking, deleteEvents, refreshTimeline, updateSchedule} from '../../../services/timeline';
import moment from 'moment';
import 'moment-timezone';
import {ApiTimelineItemType, SidePanelItemType, SyncfusionLicenseKey, ViewType} from '../../../constants';
import {setSelected} from "../../../redux/reducers/mainTable";
import {DeleteOutlined} from "@ant-design/icons";
import {registerLicense} from "@syncfusion/ej2-base";
import {openNew, setTemporaryDates} from "../../../redux/reducers/detailsPanel";
import './ScheduleView.scss';
import useDateTimeFormat from "../../../hooks/useDateTimeFormat";

const ScheduleView = ({items, loadItemToEdit}) => {
    registerLicense(SyncfusionLicenseKey);

    const scheduleObj = useRef(null);
    const dispatch = useDispatch();
    const viewType = ViewType.Month;

    const [isMonthView, setIsMonthView] = useState(false);
    const [selectedEvents, setSelectedEvents] = useState([]);
    const dateTimeFormats = useDateTimeFormat();
    const activePortfolio = useSelector((state) => state.projects.activePortfolio);
    const [keyToForceReRenderOfCalendar, setKeyToForceReRenderOfCalendar] = useState(Date.now());
    const timeZoneMappings = useSelector((state) => state.infrastructure.timeZoneMappings);
    const selectedTimeZone = useSelector((state) => state.timeline.selectedTimeZone);
    const {startDate, endDate} = useSelector((state) => state.timeline);
    const projects = useSelector(state => state.projects?.projects);
    const selectedProjects = useSelector((state) => state.projects.activeProjects);
    const projectArray = Array.isArray(projects) ? projects : [];

    const calculateInterval = (startDate, endDate) => {
        if (!startDate || !endDate || !moment(startDate).isValid() || !moment(endDate).isValid()) {
            console.error("Invalid startDate or endDate");
            return 0;
        }

        const start = moment(startDate);
        const end = moment(endDate);

        if (start.year() === end.year() && start.month() === end.month()) {
            return 1;
        }

        const diff = end.diff(start, 'months');

        if (diff === 0 && (start.month() !== end.month() || start.year() !== end.year())) {
            return 2;
        }

        return diff + 1;
    };

    const [interval, setInterval] = useState(calculateInterval(startDate, endDate));

    useEffect(() => {
        if (scheduleObj.current) {
            const initialView = scheduleObj.current.currentView;
            if (initialView === viewType) {
                setIsMonthView(true);
            } else {
                setIsMonthView(false);
            }
        }
    }, []);

    useEffect(() => {
        const newInterval = calculateInterval(startDate, endDate);

        setInterval(newInterval);
        setKeyToForceReRenderOfCalendar(Date.now());

    }, [startDate, endDate]);

    useEffect(() => {
        document.addEventListener('mouseup', mouseupHandler);
        return () => {
            document.removeEventListener('mouseup', mouseupHandler);
        };
    }, []);

    useEffect(() => {
        const scheduleElement = scheduleObj.current?.element;

        if (!scheduleElement) return;

        let previousWidth = scheduleElement.offsetWidth;
        let previousHeight = scheduleElement.offsetHeight;

        const handleResize = () => {
            const currentWidth = scheduleElement.offsetWidth;
            const currentHeight = scheduleElement.offsetHeight;

            if (currentWidth !== previousWidth || currentHeight !== previousHeight) {
                setKeyToForceReRenderOfCalendar(Date.now());
                previousWidth = currentWidth;
                previousHeight = currentHeight;
            }
        };

        const resizeObserver = new ResizeObserver(handleResize);
        resizeObserver.observe(scheduleElement);

        return () => {
            resizeObserver.disconnect();
        };
    }, []);

    const getCellContent = (date) => {
        const day = date.getDate();
        const month = date.toLocaleString('default', {month: 'short'});
        const year = date.getFullYear();

        const formattedDate = day === 1 ? `${day} <span class="date-highlight">${month} ${year}</span>` : `${day} <span class="date-highlight">${month}</span>`;

        return `<div>${formattedDate}</div>`;
    };

    const onRenderCell = (args) => {
        const start = new Date(startDate);
        const end = new Date(endDate);

        start.setHours(0, 0, 0, 0);
        end.setHours(0, 0, 0, 0);

        if (args.date < start || args.date > end) {
            args.element.classList.add('e-disable-dates');
        }
    };

    const isMidnight = (timeString) => {
        const time = moment(timeString, 'HH:mm:ss');
        return time.hours() === 0 && time.minutes() === 0 && time.seconds() === 0;
    };

    const mouseupHandler = (e) => {
        if (!scheduleObj.current || !scheduleObj.current.element || !e.target) {
            return;
        }

        const target = e.target.classList.contains('e-work-cells');
        const selectedCells = scheduleObj.current.element.querySelectorAll(".e-selected-cell");

        if (selectedCells.length > 1 && target) {
            const hasDisabledCell = Array.from(selectedCells).some(cell => cell.classList.contains('e-disable-dates'));
            if (hasDisabledCell) {
                return;
            }

            const activeCellsData = scheduleObj.current.getCellDetails(scheduleObj.current.getSelectedElements());

            handleCellClick({
                startTime: activeCellsData.startTime, endTime: activeCellsData.endTime, element: e.target
            });
        }
    };

    const getEventOrBooking = (selected) => (selected.ItemType === ApiTimelineItemType.Booking ? "booking" : "event");
    const getEventsOrBookings = (selected) => (selected.ItemType === ApiTimelineItemType.Booking ? "bookings" : "events");
    const getProject = (id) => projects.find((p) => p.Id === id);

    const mappedData = useMemo(() => {
        return items.map(event => {
            const startTime = event.StartDateTime ? new Date(event.StartDateTime) : null;
            let endTime = event.EndDateTime ? new Date(event.EndDateTime) : null;

            const project = projectArray.find(x => x.Id === event.ProjectId);
            const projectTimeZoneId = project ? project.TimeZoneId : null;
            const projectColor = project ? project.Color : null;
            const projectShortName = selectedProjects.length === 1 ? null : project?.ShortName;

            if (isMidnight(startTime) && isMidnight(endTime)) {
                endTime = moment(endTime).add(1, 'day').toDate();
            }

            return {
                Id: event.Id,
                Subject: projectShortName ? `${event.Name} [${projectShortName}]` : event.Name,
                StartTime: startTime,
                EndTime: endTime,
                StartTimeOrigin: startTime,
                EndTimeOrigin: endTime,
                IsAllDay: event.IsAllDay,
                ItemType: event.ItemType,
                IsFavorite: event.IsFavorite,
                ProjectId: event.ProjectId,
                ProjectTimeZoneId: projectTimeZoneId,
                ProjectColor: projectColor,
                ProjectShortName: projectShortName,
            };
        });
    }, [items]);

    const getDeleteConfirmationTitle = (selected) => {
        if (selected.length === 1) {
            return `Are you sure you want to delete this ${getEventOrBooking(selected[0])} from ${getProject(selected[0].ProjectId)?.Name || ""}?`;
        } else {
            return `Are you sure you want to delete ${selected.length} ${getEventsOrBookings(selected[0])}?`;
        }
    };

    const getEventDetails = (args) => {
        const projectTimeZoneId = timeZoneMappings[args.data.ProjectTimeZoneId] || "America/New_York";
        const timelineSelectedTimeZoneId = timeZoneMappings[selectedTimeZone] || "America/New_York";

        const startTime = moment(args.data.StartTime);
        const endTime = moment(args.data.EndTime);

        return {projectTimeZoneId, timelineSelectedTimeZoneId, startTime, endTime};
    };

    const getDatesPayload = (startDate, endDate, projectTimeZoneId, selectedTimeZoneId) => {
        if (isMidnight(startDate) && isMidnight(endDate)) {
            return {
                StartDate: startDate.format('YYYY-MM-DD'),
                EndDate: endDate.format('YYYY-MM-DD'),
                StartTime: null,
                EndTime: null,
            };
        }

        const startInSelectedTimeZone = moment.tz({
            year: startDate.year(),
            month: startDate.month(),
            date: startDate.date(),
            hour: startDate.hour(),
            minute: startDate.minute(),
            second: startDate.second()
        }, selectedTimeZoneId);

        const endInSelectedTimeZone = moment.tz({
            year: endDate.year(),
            month: endDate.month(),
            date: endDate.date(),
            hour: endDate.hour(),
            minute: endDate.minute(),
            second: endDate.second()
        }, selectedTimeZoneId);

        const startTimeConverted = startInSelectedTimeZone.tz(projectTimeZoneId).format('HH:mm:ss');
        const endTimeConverted = endInSelectedTimeZone.tz(projectTimeZoneId).format('HH:mm:ss');

        const startDateConverted = startInSelectedTimeZone.tz(projectTimeZoneId).format('YYYY-MM-DD');
        const endDateConverted = endInSelectedTimeZone.tz(projectTimeZoneId).format('YYYY-MM-DD');

        return {
            StartDate: startDateConverted,
            EndDate: endDateConverted,
            StartTime: isMidnight(startTimeConverted) ? null : startTimeConverted,
            EndTime: isMidnight(endTimeConverted) ? null : endTimeConverted,
        };
    };

    const updateEventDateAndTime = (args, datesPayload) => {

        const updatePayload = {
            Id: args.data.Id, CurrentProjectId: args.data.ProjectId, ...datesPayload,
        };

        updateSchedule(updatePayload)
            .then((response) => {
                refreshTimeline(ApiTimelineItemType.Task);
            })
            .catch((err) => {
                const errorText = err.response?.data?.message || 'An error occurred';
                message.error(errorText);
            });
    };

    const setFromOriginTime = (startDate, endDate, args) => {
        let startTimeOrigin = moment(args.data.StartTimeOrigin);
        let endTimeOrigin = moment(args.data.EndTimeOrigin);

        startDate.set({
            hour: startTimeOrigin.hour(),
            minute: startTimeOrigin.minute(),
            second: startTimeOrigin.second(),
            millisecond: startTimeOrigin.millisecond()
        });

        endDate.set({
            hour: endTimeOrigin.hour(),
            minute: endTimeOrigin.minute(),
            second: endTimeOrigin.second(),
            millisecond: endTimeOrigin.millisecond()
        });

        return {startDate, endDate};
    };

    const adjustEndDateForResize = (startDate, endDate, args) => {
        if (args.data.IsAllDay) {
            endDate = endDate.subtract(1, 'days');
        } else {
            const isResizedFromLeft = startDate.hour() === 0 && startDate.minute() === 0 && startDate.second() === 0;
            if (!isResizedFromLeft) {
                endDate = endDate.subtract(1, 'day');
            }
        }
        return endDate;
    };

    const adjustEndDateIfMidnight = (endDate) => {
        if (endDate.hours() === 0 && endDate.minutes() === 0 && endDate.seconds() === 0) {
            return endDate.subtract(1, 'days');
        }
        return endDate;
    };

    const handleResizeStop = (args) => {
        const {projectTimeZoneId, timelineSelectedTimeZoneId, startTime, endTime} = getEventDetails(args);

        let startDate = startTime;
        let endDate = endTime;

        if (isMonthView) {
            endDate = adjustEndDateForResize(startDate, endDate, args);

            const result = setFromOriginTime(startDate, endDate, args);
            startDate = result.startDate;
            endDate = result.endDate;
        }

        const datesPayload = getDatesPayload(startDate, endDate, projectTimeZoneId, timelineSelectedTimeZoneId);

        updateEventDateAndTime(args, datesPayload);
    };


    const handleDragStop = (args) => {
        const {projectTimeZoneId, timelineSelectedTimeZoneId, startTime, endTime} = getEventDetails(args);

        let endDate = endTime;
        endDate = adjustEndDateIfMidnight(endDate);

        const datesPayload = getDatesPayload(startTime, endDate, projectTimeZoneId, timelineSelectedTimeZoneId);

        updateEventDateAndTime(args, datesPayload);
    };


    const handleDeleteClick = (args) => {
        let selected;
        let dataObj = args.data;

        if (typeof dataObj === 'object' && dataObj !== null && !Array.isArray(dataObj)) {
            const hasNumericalKeys = Object.keys(dataObj).every(key => !isNaN(key));
            if (!hasNumericalKeys) {
                selected = [args.data];
            } else {
                selected = Object.values(args.data).flat()
            }
        }

        Modal.confirm({
            title: getDeleteConfirmationTitle(selected),
            icon: <DeleteOutlined/>,
            content: "You cannot undo this action",
            className: "delete-contact-modal",
            okText: "Delete",
            okButtonProps: {danger: true},
            async onOk() {
                const ids = selected.map((b) => b.Id);

                if (selected[0].ItemType === ApiTimelineItemType.Task) {
                    await deleteEvents(ids);
                } else if (selected[0].ItemType === ApiTimelineItemType.Booking) {
                    await deleteBooking(ids);
                }

                await refreshTimeline(selected[0].ItemType);

                if (selected.length === 1) {
                    message.success(`${selected.length} ${selected[0].ItemType === ApiTimelineItemType.Booking ? "booking" : "event"} was deleted.`);
                } else {
                    message.success(`${selected.length} ${selected[0].ItemType === ApiTimelineItemType.Booking ? "bookings" : "events"} were deleted.`);
                }

                dispatch(setSelected([]));
            },
            onCancel() {
            },
        });
    };

    const handleEventDoubleClick = (args) => {
        if (!args.element || args.element.classList.contains('e-disable-dates')) {
            return;
        }

        loadItemToEdit({
            Id: args.data.Id, Name: args.data.Subject, ProjectId: args.data.ProjectId, ItemType: args.data.ItemType,
        });
    };

    const handleEventClick = (args) => {
        if (args.event.ctrlKey) {
            const alreadySelected = selectedEvents.find(event => event.Id === args.data.Id);
            if (alreadySelected) {
                setSelectedEvents(selectedEvents.filter(event => event.Id !== args.data.Id));
            } else {
                setSelectedEvents([...selectedEvents, args.data]);
            }
        } else {
            setSelectedEvents([args.data]);
        }
    };

    const handleCellClick = (args) => {
        args.cancel = true;

        if (!args.element || args.element.classList.contains('e-disable-dates')) {
            return;
        }

        const type = SidePanelItemType.Event;

        const projectTimeZoneId = timeZoneMappings[selectedTimeZone] || "America/New_York";
        const timelineSelectedTimeZoneId = timeZoneMappings[selectedTimeZone] || "America/New_York";

        let endDate = moment(args.endTime);
        endDate = adjustEndDateIfMidnight(endDate);

        const datesPayload = getDatesPayload(moment(args.startTime), endDate, projectTimeZoneId, timelineSelectedTimeZoneId);

        setTimeout(() => {
            dispatch(openNew({type}));

            dispatch(setTemporaryDates({
                StartDate: datesPayload.StartDate, EndDate: datesPayload.EndDate,
            }));
        }, 100);
    };

    const handlePopupOpen = (args) => {
        if (args.type === 'QuickInfo') {
        } else if (args.type === 'Editor') {
            args.cancel = true;
            handleEventDoubleClick(args);
        } else if (args.type === 'DeleteAlert') {
            args.cancel = true;
            handleDeleteClick(args);
        }
    };

    const onEventRendered = (args) => {
        applyCategoryColor(args, scheduleObj.current?.currentView);
    };

    const applyCategoryColor = (args, currentView) => {
        if (args.data.ProjectColor) {
            args.element.style.backgroundColor = args.data.ProjectColor;
            args.element.style.color = 'rgba(0,0,0,.851)';
        }
    };

    const customEventTemplate = useCallback((props) => {
        const startTime = props.StartTime ? moment(props.StartTime).format(dateTimeFormats.time) : null;
        const endTime = props.EndTime ? moment(props.EndTime).format(dateTimeFormats.time) : null;

        const isValidTime = (time) => time === "00:00" || time === "12:00 AM" || time === "12:00am";

        const isEventTimeNotSet = isValidTime(startTime) && isValidTime(endTime);
        const isSingleDayEvent = moment(props.StartTime).isSame(props.EndTime, 'day');

        const eventNameClass = props.IsFavorite && viewType === ViewType.Month ? 'favorite' : '';

        return (
            <>
                {!isEventTimeNotSet && <div className="e-time">{startTime}</div>}
                <div className={`e-subject e-text-center ${eventNameClass}`}>
                    {props.Subject}
                </div>
                {!isEventTimeNotSet && !isSingleDayEvent && <div className="e-time">{endTime}</div>}
            </>
        );
    }, [dateTimeFormats.time, viewType]);

    const customCellTemplate = useCallback((props) => {
        if (props.type === "monthCells") {
            return (<div dangerouslySetInnerHTML={{__html: getCellContent(props.date)}}></div>);
        }

        return null;
    }, []);

    return (<div className="schedule-view">
        <ScheduleComponent
            key={keyToForceReRenderOfCalendar}
            ref={scheduleObj}
            eventSettings={{
                dataSource: mappedData,
                template: customEventTemplate,
                allowDragAndDrop: true, allowResizing: true
            }}
            firstDayOfWeek={activePortfolio?.DateTimeSettings?.StartWeekOn?.Value ?? 1}
            currentView={ViewType.Month}
            selectedDate={startDate ? startDate : undefined}
            width='100%'
            height='100%'
            className="full-height"
            dragStop={handleDragStop}
            resizeStop={handleResizeStop}
            renderCell={onRenderCell}
            rowAutoHeight={true}
            popupOpen={handlePopupOpen}
            eventClick={handleEventClick}
            cellClick={handleCellClick}
            eventRendered={onEventRendered}
            showHeaderBar={false}
            cellTemplate={customCellTemplate}
        >
            <ViewsDirective>
                <ViewDirective option={ViewType.Month} displayName={`${interval} Month`} interval={interval}/>
            </ViewsDirective>
            <Inject services={[Month, DragAndDrop, Resize]}/>
        </ScheduleComponent>
    </div>);
};

export default ScheduleView;