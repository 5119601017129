import React, {useState, useEffect, useRef, memo, useMemo} from "react";
import {Table} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import {setSelected, setFiltered} from "../../../redux/reducers/mainTable";
import useWindowSize from "../../../hooks/useWindowSize";
import {useVT} from "virtualizedtableforantd4";
import ViewSelector from "../../Timeline/ViewSelector/ViewSelector";
import './TableWithGroups.scss';
import {TimeLineTab, ViewMode} from "../../../constants";
import ScheduleView from "../ScheduleView/ScheduleView";

const getRowIdDefault = (record) => record ? `${record.Id}_type_${record.ItemType || 0}` : undefined;

const TableWithGroups = ({
                             items = [],
                             columns = [],
                             FilterBar,
                             GroupTable,
                             loading,
                             rowClassName,
                             loadItemToEdit,
                             groupFooter,
                             filterBarProps = {},
                             activeKey,
                             renderCell,
                             actions = [],
                             footer,
                             enableInfinityScrolling = false,
                             preserveFilteredData = false,
                             hideRowSelection = false,
                             actionsColumnWidth,
                             sortOrder,
                             onChange,
                             onGroupByChange,
                         }) => {
    const dispatch = useDispatch();
    const size = useWindowSize();
    const [filteredData, setFilteredData] = useState(items);
    const [groupBy, setGroupBy] = useState();
    const location = useLocation();
    const tableRef = useRef();
    const tableHeight = (size?.height || 800) - (tableRef.current?.offsetTop || 0) - 40;
    const [vt] = useVT(() => ({scroll: {y: tableHeight}}), [tableHeight, items]);
    const itemToEdit = useSelector((state) => state.detailsPanel.item);
    const selected = useSelector((state) => state.mainTable.selected);
    const currentView = useSelector((state) => state.timeline.currentView);

    useEffect(() => {
        dispatch(setSelected([]));

        if (preserveFilteredData) {
            dispatch(setFiltered([]));
        }
    }, [location, dispatch]);

    useEffect(() => {
        if (onGroupByChange) {
            onGroupByChange(groupBy);
        }
    }, [groupBy, onGroupByChange]);

    const handleChange = (pagination, filters, sorter, extra) => {
        if (onChange) {
            onChange(pagination, filters, sorter, extra);
        }
    };

    const onRowSelectionChange = (selection) => {
        const selectedIds = (selected || []).map(getRowIdDefault);
        const filteredDataIds = filteredData.map(getRowIdDefault);
        const prevSelectedItems = selectedIds.filter((i) => !filteredDataIds.includes(i));
        const selectedItems = filteredData.filter((i) => selection.includes(getRowIdDefault(i)) || prevSelectedItems.includes(getRowIdDefault(i)));
        dispatch(setSelected(selectedItems));
    };

    const selectedRowKeys = useMemo(() => (selected || []).map(getRowIdDefault), [selected.length]);

    function applyFilters(filtersData) {
        setFilteredData(filtersData);

        if (preserveFilteredData) {
            dispatch(setFiltered(filtersData));
        }
    }

    const calculatedActionsColumnWidth = actionsColumnWidth ?? (actions.length > 0 ? `${actions.length * 24 + 2}px` : "100px");

    const actionsColumn = {
        key: "action",
        className: "actions-column",
        width: calculatedActionsColumnWidth,
        alwaysVisible: true,
        render: (record) => {
            return (<div style={{minWidth: calculatedActionsColumnWidth}}>
                {actions.map((ActionComponent, i) => (<ActionComponent key={i} record={record}/>))}
            </div>);
        },
    };

    const renderTable = (isGroupBy, isInfinityScrolling, tableHeight, columns, actionsColumn, filteredData, loading, getRowIdDefault, rowClassName, defaultRowClassName, hideRowSelection, selectedRowKeys, renderCell, onRowSelectionChange, handleChange, loadItemToEdit, footer) => {
        return isGroupBy ? (<GroupTable
            loading={loading}
            columns={[...columns, actionsColumn]}
            dataSource={filteredData}
            groupByFilter={groupBy}
            enableInfinityScrolling={isInfinityScrolling}
            rowSelection={{type: "checkbox"}}
            onRow={(record) => ({onClick: () => loadItemToEdit(record)})}
            footer={footer}
        />) : (<Table
            ref={tableRef}
            loading={loading}
            scroll={{y: tableHeight}}
            columns={[...columns, actionsColumn]}
            dataSource={filteredData}
            sortOrder={sortOrder}
            pagination={false}
            rowKey={getRowIdDefault}
            className="common-table"
            size="small"
            components={isInfinityScrolling ? vt : null}
            rowClassName={rowClassName || defaultRowClassName}
            rowSelection={hideRowSelection ? null : {
                type: "checkbox", selectedRowKeys, renderCell, onChange: (selected) => onRowSelectionChange(selected),
            }}
            onRow={(record) => ({onClick: () => loadItemToEdit(record)})}
            onChange={handleChange}
            summary={filteredData.length > 1 ? groupFooter : null}
        />);
    };

    const defaultRowClassName = (record) => (record && itemToEdit && record.Id === itemToEdit.Id ? "row-selected-for-edit" : "");

    const renderContent = () => {
        if (activeKey === TimeLineTab.Events) {
            if (currentView === ViewMode.List) {
                return renderTable(groupBy, enableInfinityScrolling, tableHeight, columns, actionsColumn, filteredData, loading, getRowIdDefault, rowClassName, defaultRowClassName, hideRowSelection, selectedRowKeys, renderCell, onRowSelectionChange, handleChange, loadItemToEdit, footer);
            } else if (currentView === ViewMode.Visual) {
                return <ScheduleView items={filteredData}
                                     loadItemToEdit={loadItemToEdit}/>;
            }
        }
        return renderTable(groupBy, enableInfinityScrolling, tableHeight, columns, actionsColumn, filteredData, loading, getRowIdDefault, rowClassName, defaultRowClassName, hideRowSelection, selectedRowKeys, renderCell, onRowSelectionChange, handleChange, loadItemToEdit, footer);
    };

    return (<div className="table-with-groups">
        <div className="header-row">
            <div className="filter-bar-container">
                <FilterBar
                    items={items}
                    applyFilters={applyFilters}
                    applyGroupBy={setGroupBy}
                    viewMode={currentView}
                    {...filterBarProps}
                />
            </div>
            <div className="view-selector-container">
                {activeKey === TimeLineTab.Events && <ViewSelector/>}
            </div>
        </div>
        {renderContent()}
    </div>);
};

export default memo(TableWithGroups);