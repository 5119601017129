import React from "react";
import {Radio} from "antd";
import {ViewMode} from "../../../constants";
import {useSelector, useDispatch} from "react-redux";
import {setCurrentView} from "../../../redux/reducers/timeline";

const ViewSelector = () => {
    const dispatch = useDispatch();
    const currentView = useSelector((state) => state.timeline.currentView);

    const handleViewChange = e => {
        dispatch(setCurrentView(e.target.value));
    };

    return (
        <Radio.Group
            optionType="button"
            buttonStyle="solid"
            onChange={handleViewChange}
            value={currentView}
        >
            <Radio.Button value={ViewMode.List}>List</Radio.Button>
            <Radio.Button value={ViewMode.Visual}>Visual (Beta)</Radio.Button>
        </Radio.Group>
    );
};

export default ViewSelector;