import React, {useState, useEffect} from "react";
import DateFilter from "./DateFilter";
import BookingStatusFilter from "./StatusFilter/BookingStatusFilter";
import StatusFilter from "./StatusFilter/StatusFilter";
import DepartmentsFilter from "./DepartmentsFilter";
import CategoriesFilter from "./CategoriesFilter";
import TeamFilter from "./TeamFilter";
import LocationsFilter from "./LocationsFilter";
import PositionFilter from "./PositionFilter";
import ChangedAtFilter from "../../Common/Tables/FilterBar/ChangedAtFilter/ChangedAtFilter";
import SearchFilter from "./SearchFilter/SearchFilter";
import GroupBy from "./GroupBy/GroupBy";
import FilterBar from "../../Common/Tables/FilterBar/FilterBar";
import {SidePanelItemType, ViewMode} from "../../../constants/constants";
import TimelineColumnsVisibility from "./ColumnsVisibility/TimelineColumnsVisibility";
import DateSelector from "./DateSelector";

const filters = {
    [SidePanelItemType.Event]: {
        [ViewMode.List]: [
            DateSelector,
            StatusFilter,
            LocationsFilter,
            TeamFilter,
            CategoriesFilter,
            DepartmentsFilter,
            ChangedAtFilter,
            SearchFilter,
        ],
        [ViewMode.Visual]: [
            DateSelector,
            StatusFilter,
            LocationsFilter,
            TeamFilter,
            CategoriesFilter,
            DepartmentsFilter,
            ChangedAtFilter,
            SearchFilter,
        ],
    },
    [SidePanelItemType.Booking]: [
        DateFilter,
        TeamFilter,
        PositionFilter,
        BookingStatusFilter,
        ChangedAtFilter,
        SearchFilter
    ],
};

const TimelineFilterBar = ({type, viewMode, ...props}) => {
    const filterComponents = type === SidePanelItemType.Event ? filters[type][viewMode] : filters[type];

    return (
        <FilterBar
            {...props}
            filterComponents={filterComponents}
            type={type}
            GroupByComponent={GroupBy}
            ColumnsVisibilityComponent={type === SidePanelItemType.Event ? TimelineColumnsVisibility : null}
        />
    );
};

export default TimelineFilterBar;